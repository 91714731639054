.partner_statistics {
    /* max-width: 1200px; */
    margin: 0 auto;
    padding: 15px;
    padding-right: 0;
    padding-left: 0px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding-bottom: 5px;

}

.partner_statistics h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
}

.partner_statistics form {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 7px;
    flex-direction: row;
    align-items: flex-end;
    margin-left: 5px;
    margin-right: 5px;
}

.partner_statistics form div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.partner_statistics label {
    font-weight: 500;
    margin-bottom: 5px;
    font-size: 0.8rem;
    margin-right: 2px;
}

.partner_statistics input[type="date"] {
    padding: 2px;
    border: 1px solid #ccc;
    border-radius: 5px;
    
}

.partner_statistics button {
    padding: 3px 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.partner_statistics button:hover {
    background-color: #0056b3;
}

.partner_statistics button:disabled {
    background-color: #c0c0c0;
    cursor: not-allowed;
}

.statistics_chart {
    margin-bottom: 40px;
}

.statistics_table {
    overflow-x: auto;
}

.statistics_table table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.statistics_table th, .statistics-table td {
    padding: 10px;
    text-align: center;
    border: 1px solid #ddd;
}

.statistics_table th {
    background-color: #f4f4f4;
    font-weight: bold;
}

.statistics_table td {
    background-color: #fff;
}

.statistics_table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.statistics_table tr:hover {
    background-color: #f1f1f1;
}

.statistics_table .total-row {
    font-weight: bold;
    background-color: #e8f4fd;
    color: #333;
}

.statistics_container p {
    text-align: center;
    font-size: 16px;
    color: #e74c3c;
}

/* .statistics_container{
    display: flex;
    flex-direction: row;
} */


.statistics_container {
    display: flex;
    flex-wrap: wrap;
    gap: 0px;
    justify-content: flex-start;
}

.statistics_container div {
    /* flex-grow: 1;
    flex-shrink: 1; */
    flex-basis: calc(20% - 20px);
    box-sizing: border-box;
    /* padding: 10px; */
    /* border: 1px solid #ddd; */
    border-radius: 8px;
    text-align: center;
}

.statistics_container h3{
    margin: 1px;
}

/* Адаптивный стиль для экранов меньшей ширины */
/* @media (max-width: 430px) {
    .statistics_container div {
        flex: 1 1 40%; 
    }
} */
