.create_placeholder {
    width: 100px;
    height: 135px;
    /* display: inline-block; */
    min-height: 1em;
    /* vertical-align: middle; */
    cursor: pointer;
    background-color: #7a828229;
    background-image: url('../default3.jpg');
    border-radius: 10px;
    font-weight: 700;
    display: flex;              /* Используем Flexbox */
    justify-content: center;    /* Горизонтальное центрирование */
    align-items: center;
    opacity: .9;
    border: 1px solid #00000059;
}

.create_photo_grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 5px;
    gap: 15px;
    width: 100%;
}

.create_photo_grid img {
    /* filter: blur(7px); */
    /* position: absolute; */
    top: 0;
    left: 0;
    width: 100px;
    height: 135px;
    border-radius: 10px;
    object-fit: cover; /* Масштабируем изображение по карточке, обрезая по наименьшей стороне */
    object-position: center; /* Центрируем изображение */
    
}

.create_edit_form {
    display: flex;
    min-height: 420px;
    align-items: center;
    padding: 20px;
    background-color: var(--background-color);
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    justify-content: center;
}

.create_form_group{
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}

.create_batton_x {

    position: absolute;
    background-image: url('../x.svg');
    background-color: unset;
    bottom: 0;
    right: 0;
    height: 25px;
    width: 25px;
    border: unset;
    background-repeat: no-repeat;
    padding: unset;
}

.photo_card {
    position: relative;
}

.btn_edit_container{
    display: flex;
    justify-content: center; /* Центрируем форму по горизонтали */
    align-items: center; /* Центрируем форму по вертикали */
    /* height: 100vh !important; Контейнер занимает всю высоту экрана */
    /* width: 100vh !important; */
}

.btn_edit button {
    font-weight: bold; 
    background-color: #ff4081; /* Основной цвет кнопки */
    color: white ; /* Цвет текста */
    border: none ; /* Убираем границу */
    padding: 12px 24px ; /* Размер кнопки */
    font-size: 1rem ; /* Размер текста */
    border-radius: 5px ; /* Скругленные углы */
    cursor: pointer ; /* Меняем курсор на указатель при наведении */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Тень кнопки */
    transition: background-color 0.3s ease, transform 0.3s ease ; /* Плавная анимация */
    margin: 10px 5px ; /* Отступы между кнопками */
}

.btn_edit button:hover {
    background-color: #e63974 ; /* Цвет кнопки при наведении */
    transform: translateY(-3px); /* Плавный подъем кнопки при наведении */
}

.btn_edit button:active {
    background-color: #c72b5f ; /* Цвет кнопки при клике */
    transform: translateY(0) ; /* Кнопка возвращается на место при клике */
}

.btn_edit button:focus {
    outline: none ; /* Убираем стандартную обводку фокуса */
}


.btn_prev{
    position: absolute;
    border-radius: 50%;
    top: 15%;
    left: 5%;
    background-color: #8e919187;
    z-index: 45;
    color: white;
    width: 30px;
    height: 30px;
    border: 0;
}

.btn_prev2{
    position: absolute;
    border-radius: 50% !important;
    top: 15%;
    left: 5%;
    background-color: #8e919187 !important;
    z-index: 45;
    color: white !important;
    width: 30px;
    height: 30px;
    padding: 0 !important;
    border: 0 !important;
    font-size: unset !important;
    margin: 0 !important;
}

.geo{
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    gap: 7px;
    text-align: center;
    margin-top: 40px;
}


.label_city{
    align-items: start;
    display: flex;
}


.btn_edit_city button {
    font-weight: bold; 
    background-color: #ff4081; /* Основной цвет кнопки */
    color: white ; /* Цвет текста */
    border: none ; /* Убираем границу */
    padding: 12px 24px ; /* Размер кнопки */
    font-size: 1rem ; /* Размер текста */
    border-radius: 5px ; /* Скругленные углы */
    cursor: pointer ; /* Меняем курсор на указатель при наведении */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Тень кнопки */
    transition: background-color 0.3s ease, transform 0.3s ease ; /* Плавная анимация */
    margin: 32px 5px 0; /* Отступы между кнопками */
}

.btn_edit_city button:hover {
    background-color: #e63974 ; /* Цвет кнопки при наведении */
    transform: translateY(-3px); /* Плавный подъем кнопки при наведении */
}

.btn_edit_city button:active {
    background-color: #c72b5f ; /* Цвет кнопки при клике */
    transform: translateY(0) ; /* Кнопка возвращается на место при клике */
}

.btn_edit_city button:focus {
    outline: none ; /* Убираем стандартную обводку фокуса */
}