.statistics_table {
    width: 100%;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
  }
  
  .statistics_table th, .statistics_table td {
    border: 1px solid #ddd;
    padding: 3px;
    text-align: center;
  }
  
  .statistics_table th {
    background-color: #fce3a7;
    color: #414141;
    font-weight: bold;
    position: sticky;
  top: -1px; /* Фиксирует заголовок таблицы сверху */
  }
  
  .statistics_table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .statistics_table tr:hover {
    background-color: #ddd;
  }
  
  .details_button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 8px 12px;
    cursor: pointer;
    font-size: 14px;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  .details_button:hover {
    background-color: #003cff;
  }
  
  .details_row {
    background-color: #f9f9f9;
  }
  
  .details_content {
    padding: 10px;
    text-align: left;
    font-size: 14px;
    color: #333;
  }
  
  .details_content ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .details_content li {
    padding: 4px 0;
  }
  
  .scrollContainer {
    /* max-width: 1200px; */
    max-height: 400px;
    overflow: auto;
    border: 1px solid #ddd;
    /* padding: 10px; */
    border-radius: 8px;
    background-color: #fafafa;
    margin: 0 auto;
    padding-top: 0;
  }
  