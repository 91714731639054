/* Основные стили */
.slider_div{
  display: flex;
    justify-content: center;
    padding-top: 10px;
}
.settings_page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-x: hidden; /* Запрещаем горизонтальную прокрутку */
  }
  
  .settings_header {
    text-align: center;
    margin-bottom: 0px;
  }
  
  .settings_menu ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
  }
  
  .settings_menu ul li {
    margin: 10px 0;
  }
  
  .settings_menu ul li a,
  .settings_menu ul li button {
    text-decoration: none;
    font-size: 18px;
    color: #414141;
    border: none;
    background: none;
    cursor: pointer;
  }
  
  .settings_options {
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    
  }
  
  .settings_options h2 {
    margin-bottom: 20px;
  }
  
  .setting_item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    width: 80vw;
    max-width: 450px;
    
  }
  
  /* Темная тема */
  .dark_mode {
    background-color: #333;
    color: white;
  }
  
  .dark_mode .settings_menu ul li a,
  .dark_mode .settings_menu ul li button {
    color: #fff;
  }
  
  .back{
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 5px;
    width: 95vw;
    max-width: 670px;
  }

  .btn_prev_2{
    border-radius: 50%;
    background-color: #8e919187;
    z-index: 45;
    color: white;
    width: 20px;
    height: 20px;
    border: 0;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.profile_div {
    width: 100%;
    max-width: 650px;
    display: flex;
    justify-content: center;
    background-color: #49494938;
    border-radius: 15px;
    align-items: center;
    flex-direction: column;
    padding-top: 25px;
    padding-bottom: 25px;
    margin-bottom: 10px;
    overflow-x: hidden;
}

.profile_2 {
  width: 200px;
  height: 200px;
    display: flex;
    justify-content: center;
    border: 5px solid gray;
    /* aspect-ratio: 3 / 3; */
    border-radius: 50%;
    overflow: hidden;
    align-items: center;
}

.profile_photo_2 {
  width: 100%;
  object-fit: cover;
    
}
.name_age_2 {
  display: flex;
  flex-direction: column;
    justify-content: center; /* Распределение элементов по всей ширине */
    align-items: center; /* Выравнивание по вертикали */
    width: 100%; /* Ширина блока */
    gap: 30px; /* Добавляем расстояние между элементами */
    margin-top: 10px;
    font-weight: bold;
    font-size: 1.5em;
}

.settings_options_div{
  width: 100%;
    max-width: 650px;
    display: flex;
    justify-content: center;
    background-color: #49494938;
    border-radius: 15px;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;


}


/* Контейнер переключателя */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 28px;
}

/* Скрытый чекбокс */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* Слайдер */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #9a9a9a;
  transition: 0.4s;
  border-radius: 34px;
}

/* Круг внутри слайдера */
.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

/* Когда чекбокс активен */
input:checked + .slider {
  background-color: #ff4081;
}

/* Когда чекбокс активен, перемещаем круг */
input:checked + .slider:before {
  transform: translateX(22px);
}

/* Убираем стандартный эффект фокуса для ползунка */
.switch input:focus + .slider {
  box-shadow: none; /* Убираем тень */
}

/* Убираем выделение при нажатии на ползунок */
.switch input {
  outline: none; /* Убираем рамку вокруг чекбокса */
}

/* Убираем выделение для мобильных браузеров */
.switch {
  -webkit-tap-highlight-color: transparent; /* Убираем цвет подсветки на iPhone */
}



/* ----------внизу для профиля---------------- */

.prof_div {
  /* padding: 20px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.back2 {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 20px;
  margin-bottom: 5px;
  width: 95vw;
  max-width: 670px;

}


.profile_container {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Центрируем карточки по горизонтали */
  gap: 5px; /* Отступ между карточками */
  padding-top: 10px;
  margin-bottom: 20px; /* Добавляем больше пространства снизу */
}


.profile {
  margin-bottom: 1px ; /* Увеличиваем отступ снизу */
  display: flex;
  flex-direction: column ; /* Выстраиваем фото и описание вертикально */
  width: 99% ; /* Задаем ширину карточки */
  max-width: 450px;
  /* aspect-ratio: 3 / 4 !important; Соотношение сторон 3:4 */
  border-radius: 1px ; /* Скругляем углы */
  /* overflow: hidden !important; Скрываем части, которые выходят за границы */
  background-color:  var(--background-color) ; /* Фон карточки */
}

.img {
  /* position: relative !important; */
  width: 100%;
  /* height: 75% !important; 75% высоты отводим под фото */
  position: relative;
  aspect-ratio: 3 / 4;

}

.styled_image {
  width: 100% ;
  height: 100% ;
  object-fit: cover; /* Масштабируем изображение, сохраняя пропорции и центрирование */
  object-position: center; /* Центрируем фото внутри карточки */
}

.bio_card {
  margin-top: 1px ; /* Отступ сверху */
  text-shadow: 1px 1px 3px rgba(95, 93, 93, 0.153);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1px ; /* Внутренний отступ для текста */
  border-radius: 1px ; /* Скругляем углы */
  background-color:  var(--background-color) ; /* Фон для карточки bio */
  max-width: 450px; /* Ограничиваем максимальную ширину */
  width: 99%;
}

.bio_card p {
  margin: 5px 0; /* Отступы между элементами */
  font-size: 1rem ;
  color: var(--text-color);
  padding-left: 10px;
  padding-right: 10px;
}

.name_age {
  display: flex;
  justify-content: flex-start; /* Распределение элементов по всей ширине */
  align-items: center; /* Выравнивание по вертикали */
  width: 100%; /* Ширина блока */
  gap: 30px; /* Добавляем расстояние между элементами */
}

.profile_bio {
  font-size: 1.2rem;
  font-weight: 500;
  text-shadow: 1px 1px 3px rgba(95, 93, 93, 0.153);
  color: var(--text-color);
  padding-left: 20px; /* Внутренний отступ для текста */
  padding-top: 10px;
  text-align: left; /* Выравнивание текста по левому краю */
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Описание начинается с верха блока */
  align-items: stretch; /* Элементы растягиваются на всю ширину */
  gap: 15px;
}

.btn_profile button {
  font-weight: bold; 
  border-radius: 5px; /* Скругленные углы */
  padding: 10px; /* Внутренний отступ для кнопки */
  background-color: #ff4081; /* Цвет кнопки */
  color: white; /* Цвет текста кнопки */
  border: none; /* Убираем рамку */
  cursor: pointer; /* Изменение курсора на pointer */
  margin-top: 20px; /* Отступ сверху для размещения кнопки ниже полей */
  font-size: 1rem;
}

.btn_profile button:hover {
  background-color: #e63974; /* Цвет кнопки при наведении */
  transform: translateY(-3px); /* Плавный подъем кнопки при наведении */
}

.btn_profile button:active {
  background-color: #c72b5f; /* Цвет кнопки при клике */
  transform: translateY(0);
}

.btn_profile button:focus {
  outline: none;
}





.prev, .next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
  border-radius: 50px;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}



