/* Контейнер для формы, который занимает весь экран */
.login_container {
    display: flex;
    justify-content: center; /* Центрируем форму по горизонтали */
    align-items: center; /* Центрируем форму по вертикали */
    height: 90vh !important; /* Контейнер занимает всю высоту экрана */
    /* width: 100vh !important; */
}

.login_form {
    display: flex;
    flex-direction: column; /* Выстраиваем элементы по вертикали */
    width: 300px; /* Задаем ширину формы */
    /* margin: auto auto; Центрируем форму горизонтально */
    padding: 20px; /* Отступ внутри формы */
    background-color:#505050; /* Цвет фона формы */
    border-radius: 8px; /* Скругляем углы */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Добавляем тень */
    padding-top: 35px;
    
}

.login_form input {
    margin-bottom: 20px; /* Расстояние между полями формы */
    padding: 10px; /* Внутренний отступ для увеличения размеров полей */
    font-size: 1rem; /* Размер текста внутри полей */
    width: 80%;
}

.login_form button {
    font-weight: bold; 
    border-radius: 5px !important; /* Скругленные углы */
    padding: 10px; /* Внутренний отступ для кнопки */
    background-color: #f53d3d; /* Цвет кнопки */
    color: white; /* Цвет текста кнопки */
    border: none; /* Убираем рамку */
    cursor: pointer; /* Изменение курсора на pointer */
    margin-top: 20px; /* Отступ сверху для размещения кнопки ниже полей */
    font-size: 1rem;
    width: 43%;
}

.login_form button:hover {
    background-color: #f53d3d; /* Цвет при наведении на кнопку */
}

.login_form p {
    color: red; /* Цвет текста для ошибок */
}

form {
    display: flex;
    flex-direction: column;
    align-items: center;
}


/* Пример вашего CSS-модуля (googleSignIn.module.css) */
.google_sign_in {
    /* display: inline-block;
    background-color: #fff;
    color: #757575;
    border: 1px solid #ccc; */
    display: flex;
    justify-content: center;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 16px;
    font-family: Arial, sans-serif;
    cursor: pointer;
    box-shadow: 0 1px 2px rgba(0,0,0,0.1);
    transition: background-color 0.3s ease;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 12px;
  }
  
  .google_sign_in:hover {
    background-color: #f7f7f7;
  }
  
  .google_sign_in img {
    width: 20px;
    vertical-align: middle;
    margin-right: 10px;
  }
  