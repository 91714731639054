.home_container {
    /* position: fixed; */
    display: flex;
    justify-content: center;
    padding-top: 50px;
    /* background-image: url('./dall2.jpg'); */
    background-size: cover; /* Фон масштабируется с сохранением пропорций */
    background-position: center; /* Центрируем фон */
    background-attachment: fixed; /* Фон остается прикрепленным при прокрутке */
    background-repeat: no-repeat; /* Отключаем повторение фона */
    background-position: center; /* Центрируем изображение */
    color: white;
    
    
    
    /* overflow: hidden; */
    
    
  }
  
.home_title {
    padding-top: 50px; /* Внутренние отступы: убрано большое значение padding сверху */
    padding-bottom: 30px;
    font-size: 2.5rem;
    margin-bottom:5px; /* Внешний отступ снизу, увеличен для большей дистанции от описания */
    font-weight: 700;
    text-align: center;
}

.home_description {
    font-weight: 400;
    font-size: 1.2rem;
    margin-bottom: 50px; /* Внешний отступ снизу, чтобы было больше пространства после описания */
    text-align: center;
    max-width: 700px;
    padding-left: 15px;
    padding-right: 15px;
}

  
.button_container {
    display: flex;
    justify-content: center; /* Центрирование по горизонтали */
    align-items: center; /* Центрирование по вертикали */
    height: 100vh; /* Высота контейнера равна высоте экрана */
    

}

.get_started_btn {
    font-size: 1.5rem;
    background-color: #f53d3d;
    border: none;
    color: white; /* Цвет текста */
    border-radius: 10px;
    padding: 8px 16px;
      /* font-size: 14px; */
}

.get_started_btn:hover {
    background-color: #f53d3d;
}

  
  .features_section {
    margin-top: 60px;
    padding-top: 40px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }
  
  .feature {
    text-align: center;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .feature h3 {
    font-size: 1.8rem;
    margin-bottom: 15px;
  }
  
  .feature p {
    /* font-size: 1rem; */
    color: rgba(255, 255, 255, 0.8);
  }


.get_started {
  background-color: #403a3a;
  justify-content: space-evenly;
    background-color: #faebd700;
    display: flex;
    flex-direction: row;
    border-radius: 10px;

}


/* отключение отступа перед кнопками */
ul {  
    padding: 0;
}


.home_1 {
  background-color: #696868;
    border-radius: 20px;
    margin-top: 25%;
    margin-left: 30px;
    margin-right: 30px;
    padding-bottom: 20px;
    max-width: 450px;
    box-shadow: 5px 6px 8px #000000db;
}
