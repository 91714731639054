.offers_li {
    border: 1px solid;
    border-radius: 10px;
    padding: 13px;
    list-style-type: none;
}
.offers_ul{
display: flex;
    gap: 15px;


}
